.button-line {
  transition: all 0.5s ease;
  width: 100%;
  background-color: var(--color);
}

.button-line-container.closed .button-line {
  width: 0;
  background-color: white;
  transition: all 0.5s ease;
}

.button-line-container:hover .button-line {
  width: 0;
  background-color: white;
  transition: all 0.5s ease;
}

.button-line-button {
  transition: all 0.5s ease;
}

.button-line-container.closed .button-line-button {
  filter: none;
  background-color: transparent;
  border: 1px solid white;
}

.button-line-button:hover {
  filter: none;
  background-color: transparent;
  border: 1px solid white;
}