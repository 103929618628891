.yoga-studios-container {
  background: url("../images/yoga.png");
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
  width: 100vw;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 768px) {
  .yoga-studios-container {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}

.yoga-container {
  background: white;
}

.yoga-cards-container {
  max-width: 900px;
}

.studio-pic {
  height: auto;
  width: 100%;
  border-radius: 5px;
  filter: drop-shadow(0px 0px 5px black);
}

.yoga-info {
  filter: drop-shadow(0px 0px 5px black);
  margin-top: 1rem;
  background: #1E1E1E;
  width: 100%;
  gap: 0.25rem;
  border-radius: 5px;
}

.white-box {
  filter: drop-shadow(0px 0px 5px black);
  background: white;
  max-width: 900px;
  border-radius: 10px;
}

/* Glyph Section Two Items No Actions */
.wl-glyph-section-two-items-no-actions {
  align-items: center;
  justify-content: space-around;
  padding: 1rem;
  flex-direction: row;
  width: 100%;
  background: var(--wl-background);
}

.wl-glyph-section-two-items-no-actions-item { 
  max-width: 650px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
.wl-glyph-section-two-items-no-actions-glyph-container { height: 300px; aspect-ratio: 1/1;  }
.wl-glyph-section-two-items-no-actions-glyph-container img { height: 300px; aspect-ratio: 1/1;  }
.wl-glyph-section-two-items-no-actions h1 { font-size: 1.25rem; font-weight: 600 !important; }
.wl-glyph-section-two-items-no-actions-md-container { display: flex; flex-direction: row; align-items: center; justify-content: center; }

/* Dark Mode */
.wl-section-dark .wl-glyph-section-two-items-no-actions { background: var(--wl-background-dark);}

/* Md Styles */
@media (max-width: 991px) {
  .wl-glyph-section-two-items-no-actions { flex-direction: column; }
}