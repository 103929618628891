.App {
  text-align: center;
  box-sizing: border-box;
  max-width: 100%;
  min-height: 100vh;
  background: var(--backgroundColor);
  font-family: 'Tenali Ramakrishna', sans-serif;

  --textPurple: "#291250";
}

.app-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}
main {
  background-color: white;
}

section {
  scroll-snap-align: start;
  scroll-margin: 80px;
}

ul {
  padding-left: 0 !important;
}

ol {
  padding-left: 0rem !important;
  margin: 0rem !important;
  list-style-type: none !important;
}

ol li {
  display: block;
}

footer {
  background: #1e1e1e;
  display: flex;
  font-family: 'Tenali Ramakrishna', sans-serif;
}

nav {
  font-family: 'Tenali Ramakrishna', sans-serif;
}

nav.custom {
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  margin-bottom: -60px;
  z-index: 5;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  padding-right: 2rem;
  justify-content: flex-end;
}